/**
 *	common.js
 */
'use strict';

/* config
--------------------------------------------------*/
const APP = {};  // グローバル変数格納用
APP.spBreakPoint = 767;
APP.tbBreakPoint = 1023;

// グローバル変数設定
APP.ww = window.innerWidth ? window.innerWidth: $(window).width();
APP.wh = window.innerHeight ? window.innerHeight: $(window).height();
APP.cww = APP.ww;  // リサイズ前のウインドウ幅を保持
APP.cwh = APP.wh;  // リサイズ前のウインドウ高を保持
APP.scrollTop = $(window).scrollTop();
APP.scrollBottom = APP.scrollTop + APP.wh;
APP.isSP = (APP.ww <= APP.spBreakPoint) ? true : false;

APP.cookieData = {
  "english": false
}

/* userAgent
--------------------------------------------------*/
import { _ua } from './component/ua';
if (_ua.Tablet) {
  $('body').addClass('tablet');
} else if (_ua.Mobile) {
  $('body').addClass('mobile');
  APP.isSP = true;
} else {
  $('body').addClass('is-pc');
}
if (_ua.iOS) {
  $('body').addClass('ios');
}
if (_ua.userAgent.indexOf('msie') != -1 || _ua.userAgent.indexOf('trident') != -1) {
  $('body').addClass('ie');
} else if(_ua.userAgent.indexOf('edge') != -1) {
  $('body').addClass('edge');
}

/* LOADED
--------------------------------------------------*/
$(window).on('load', function () {
  $('body').addClass('is-loaded');
  $('.loading').fadeOut(function() {
    $(this).remove();
  });
});

/* Google Maps
--------------------------------------------------*/
let gglmap;
let zoom = 17;
/*
*  new_map
*  mapTypeId
*    ROADMAP    道路や建物などが表示される地図
*    SATELLITE  衛星写真を使った地図
*    HYBRID     ROADMAPとSATELLITEの複合した地図
*    TERRAIN    地形情報を使った地図
*/
function new_map( $target,$markerList ) {

  let $markers = $markerList.find('.marker');
  let args = {
    zoom      : zoom,
    center    : new google.maps.LatLng(0, 0),
    mapTypeId : google.maps.MapTypeId.ROADMAP
  }
  // create map
  let map = new google.maps.Map( $target[0], args);
  map.markers = [];

  // add markers
  $markers.each(function(){
    add_marker( $(this), map );
  });

  // center map
  center_map( map );

  // return
  return map;
}

/*
*  add_marker
*/
function add_marker( $marker, map ) {
  
  let _html = '';
  let _name = $marker.find('.data-name').html();
  let _genre = $marker.find('.data-genre').html();
  let _photo = $marker.find('.photo').attr('data-photo');
  let _category = $marker.find('.c-category').html();
  let _text = $marker.find('.text').html();
  let _place = $marker.find('.data-place').html();
  let _tel = $marker.find('.data-tel').html();
  let _btn = $marker.find('.place-list-item__btn').html();
  
  let iconPath = '';
  if (_genre == '駐車場') iconPath = 'http://kumagawa-juku.com/wp/wp-content/themes/kumagawajuku/assets/img/common/icon_p.png';
  // console.log(_genre);
  // marker
  let latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
  let marker = new google.maps.Marker({
    position  : latlng,
    map       : map,
    icon      : iconPath, // image path
    animation : google.maps.Animation.DROP
  });
  map.markers.push( marker );

  // info window
  if ($marker.html()) {

    let _nameHTML = (_name)? '<div class="gm-name">'+$marker.find('.data-name').html()+'</div>':'';
    let _genreHTML = (_genre)? '<div class="gm-genre">'+$marker.find('.data-genre').html()+'</div>':'';
    let _photoHTML = (_photo)? '<img class="gm-photo" src="'+$marker.find('.photo').attr('data-photo')+'">':'';
    let _categoryHTML = (_category)? '<div class="gm-category">'+$marker.find('.c-category').html()+'</div>':'';
    let _textHTML = (_text)? '<div class="gm-text">'+$marker.find('.text').html()+'</div>':'';
    let _placeHTML = (_place)? '<div class="gm-place">'+$marker.find('.data-place').html()+'</div>':'';
    let _telHTML = (_tel)? '<div class="gm-tel">'+$marker.find('.data-tel').html()+'</div>':'';
    let _btnHTML = (_btn)? $marker.find('.place-list-item__btn').html():'';

    _html += '<div class="gm-content">';
    _html += '<div class="gm-content-header">';
    _html += _nameHTML + _genreHTML + _categoryHTML;
    _html += '</div>';
    if (_text) {
      _html += '<div class="gm-content-main">';
      _html += _photoHTML + _textHTML;
      _html += '</div>';
    } else {
      _html += '<div class="gm-content-main center">';
      _html += _photoHTML;
      _html += '</div>';
    }
    _html += _placeHTML + _telHTML;
    _html += '<div class="gm-btn">' + _btnHTML + '</div>';
    _html += '</div>';

    let infowindow = new google.maps.InfoWindow({
      content : _html
    });
    google.maps.event.addListener(marker, 'click', function() {
      infowindow.open( map, marker );
    });
  }
}

/*
*  center_map
*/
function center_map( map ) {
  let bounds = new google.maps.LatLngBounds();
  $.each( map.markers, function( i, marker ){
    let latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
    bounds.extend( latlng );
  });
  if( map.markers.length == 1 ){
      map.setCenter( bounds.getCenter() );
      map.setZoom( zoom );
  } else {
    map.fitBounds( bounds );
  }
}

/* READY
--------------------------------------------------*/
$(function () {
  // $.removeCookie('kumagawa-juku');
  if ($.cookie('kumagawa-juku')) {
    var result = $.cookie('kumagawa-juku');
    APP.cookieData = JSON.parse(result);
  }
  changLanguage();
  $('.btn-language').on('click', function (e) {
    e.preventDefault();
    if (APP.cookieData.english) {
      APP.cookieData.english = false;
    } else {
      APP.cookieData.english = true;
    }
    changLanguage();
    var saveData = JSON.stringify(APP.cookieData);
    $.cookie('kumagawa-juku', saveData);
    return false;
  });

  // # で始まるリンクにスムーススクロール
  APP.scSpeed = 400;
  function scrollHdl(_href) {
    let target = $(_href == '#' || _href == '' ? 'html' : _href);
    let position = target.offset().top;
    if (!APP.isSP) {
      position -= 100;
    } else {
      position -= 0;
      $('body').removeClass('is-block');
      $('.js-btn-nav').removeClass('is-open');
      $('.js-nav').fadeOut();
    }
    $('body,html').animate({scrollTop:position}, APP.scSpeed, 'easeInOutQuad');
  }
  $('a[href^="#"]').on('click', function (e) {
    e.preventDefault();
    let href= $(this).attr('href');
    if(href=='#') return false;
    if(href=='#pagetop'){
     $('html, body').animate({scrollTop: 0}, APP.scSpeed, 'easeInOutQuad');
     return false;
    }
    scrollHdl(href);
    return false;
  });
  // 遷移後のページ内リンク
  APP.URLhash = location.hash;
  setTimeout(function() {
    if (APP.URLhash) scrollHdl(APP.URLhash);
  }, 300);

  $('.js-btn-nav').on('click', function (e) {
    e.preventDefault();
    if ($(this).hasClass('is-open')) {
      $(this).removeClass('is-open');
      $('body').removeClass('is-block');
      $('.js-nav').fadeOut();
    } else {
      $(this).addClass('is-open');
      $('body').addClass('is-block');
      $('.js-nav').fadeIn();
    }
    return false;
  });

  APP.slickObj = $('.js-slider').slick({
    autoplay: true,
    arrows: false,
    dots: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    autoplaySpeed: 4000,
    speed: 2000,
    fade: true
  });

  $('.js-acc-btn').on('click', function (e) {
    e.preventDefault();
    if (APP.noTap) return false;
    APP.noTap = true;
    if ($(this).hasClass('only-sp') && !APP.isSP) return false;
    $(this).toggleClass('is-open');
    $(this).next('.js-acc-content').stop(true, true).slideToggle(function () {
      APP.noTap = false;
    });
    return false
  });

  if ($('.news-contents-meta').length) {
    if (APP.isSP) {
      heightArrange.off($('.js-news-height'));
    }else {
      heightArrange.on($('.js-news-height'));
    }
  }

  if ($('.gglmaps').length) {
    gglmap = new_map( $('.gglmaps'),$('.place-list')); // target,markerList
  }

  var cFlag = false;
  $('.qr-btn .btn-language').on('click', function (e) {
    e.preventDefault();
    if (cFlag) return false;
    cFlag = true;
    if ($(this).find('.jp-item').css('display') == 'block') {
      $('.jp-item').hide();
      $('.en-item').fadeIn(function() {
        cFlag = false;
      });
    } else {
      $('.jp-item').fadeIn(function() {
        cFlag = false;
      });
      $('.en-item').hide();
    }
    return false;
  });
  $('.qr-contents .qr-select').on('change', function (e) {
    e.preventDefault();
    var target = $(this).val();
    $('.qr-contents .qr-item').hide();
    $('.qr-contents .qr-item.' + target).fadeIn(1000);
    return false;
  });
});

/* FUCTION
--------------------------------------------------*/
function changLanguage() {
  if (APP.cookieData.english) {
    $('body').addClass('lang-en');
    $('body').removeClass('lang-jp');
  } else {
    $('body').removeClass('lang-en');
    $('body').addClass('lang-jp');
  }
}

var heightArrange = {
  on: function(target){
    target.css('height', 'auto');
    var setH = 0;
    $.each(target, function(index, val) {
      setH = Math.max(setH,$(this).height());
    });
    target.height(setH);
  },
  off: function(target) {
    target.css('height', 'auto');
  }
};
// SCROLL
const winScroll = function () {
  // PAGETOP
  if (!APP.isSP) {
    if ((APP.scrollTop + APP.wh) < $('footer').offset().top) {
      $('#pagetop').addClass('is-fixed');
    } else {
      $('#pagetop').removeClass('is-fixed');
    }
    if (APP.scrollTop < 10) {
      $('#pagetop').fadeOut();
    } else {
      $('#pagetop').fadeIn();
    }
  }

  let _top = $(window).scrollTop();
  let scrolled = _top + Math.floor(window.parent.innerHeight * 0.9);
  $.each($('.js-anim'), function(){
    if(scrolled > parseInt($(this).offset().top)){
      if (!$(this).hasClass('inview') ) {
        $(this).addClass('inview');
      }
    }
  });
};

/* RESIZE
--------------------------------------------------*/
APP.resizeFlag = false;
$(window).on('resize orientationchange', function() {
  APP.ww = window.innerWidth ? window.innerWidth: $(window).width();
  APP.wh = window.innerHeight ? window.innerHeight: $(window).height();
  APP.scrollTop = $(window).scrollTop();
  APP.scrollBottom = APP.scrollTop + APP.wh;
  if(!APP.resizeFlag) {
    APP.resizeFlag = true;
    setTimeout(function(){
      if(APP.ww <= APP.spBreakPoint) {
        APP.isSP = true;
        if(APP.cww != APP.ww){
          APP.cww = APP.ww;
          // isSP
        }
      }else {
        APP.isSP = false;
        APP.cww = APP.ww;
        // isPC
      }
      if (_ua.iOS && APP.cwh != APP.wh) {
        if (APP.cwh < APP.wh) {
          $('body').removeClass('is-menu');
        } else {
          $('body').addClass('is-menu');
        }
        APP.cwh = APP.wh;
      }
      APP.resizeFlag = false;
      return APP.resizeFlag;
    },100);
  }
}).trigger('resize');

/* SCROLL
--------------------------------------------------*/
$(window).on('scroll',function(){
  APP.scrollTop = $(window).scrollTop();
  winScroll();
}).trigger('scroll');